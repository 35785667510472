import {
  SET_GAMES,
  ADD_GAME,
  MANAGE_GAME
} from '../shared/constants';
import {Action, GamesRedux} from '../shared/types';
import {BetGameObj} from '../../shared/types';

const selectedInitial: BetGameObj = {
  id: -1,
  createdByName: "",
  createdById: -1,
  name: "",
  amount: "", 
  lockIn: "",
  multipleChoinces:  false,
  betsValue:  false,
  choices: [""],
  value: "",
  createdAt: new Date(),
  joinedUsers: [],
  status: 'active',
  winner: {
    choice: "",
    value: "",
  },
};

const INITIAL: GamesRedux = {
  games: []  
};

export default function reducer(state = INITIAL, {
  type,
  payload
}: Action<BetGameObj | BetGameObj[]>): GamesRedux {
  switch (type) {
    case SET_GAMES:
      return {
        ...state,
        games: (payload as BetGameObj[]).map(p => ({...p}))
      };
    case ADD_GAME:
      return {
        ...state,
        games: [...state.games, (payload as BetGameObj)]
      };
      case MANAGE_GAME:
        const gameFromPayload = (payload as BetGameObj);
        return {
          ...state,
          games: state.games.map(game => game.id === gameFromPayload.id ? {
              ...game,
              status: gameFromPayload.status,
              winner: gameFromPayload.winner
            } : game )
          }     
    default:
      return state;
  }
}
