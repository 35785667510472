import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";

// Define any custom types needed for the Google IMA SDK
declare global {
  interface Window {
    google: any;
  }
}

const GoogleAdStream: React.FC<{ className?: string }> = ({ className }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const adsLoaderRef = useRef<any>(null);
  const adsManagerRef = useRef<any>(null);
  const adDisplayContainerRef = useRef<any>(null);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [adsBlocked, setAdsBlocked] = useState<boolean>(false);
  const [adsLoaded, setAdsLoaded] = useState<boolean>(false);

  useEffect(() => {
    const initIMA = () => {
      const videoElement = videoRef.current;

      if (!videoElement) {
        console.error("Video element not found");
        return;
      }

      if (!window.google || !window.google.ima) {
        console.warn(
          "Google IMA SDK is blocked by an ad blocker or not available."
        );
        setAdsBlocked(true); // Set state to handle ad blocker case
        return;
      }

      // Create the ad display container
      adDisplayContainerRef.current = new window.google.ima.AdDisplayContainer(
        document.getElementById("ad-container"),
        videoElement
      );

      // Initialize the ad display container (may require user interaction in some cases)
      adDisplayContainerRef.current.initialize();

      // Create ads loader
      adsLoaderRef.current = new window.google.ima.AdsLoader(
        adDisplayContainerRef.current
      );

      // Add ads loader event listeners
      adsLoaderRef.current.addEventListener(
        window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
        onAdsManagerLoaded,
        false
      );
      adsLoaderRef.current.addEventListener(
        window.google.ima.AdErrorEvent.Type.AD_ERROR,
        onAdError,
        false
      );

      // Request video ads
      const adsRequest = new window.google.ima.AdsRequest();
      const now = new Date().getTime();
      adsRequest.adTagUrl = `https://pubads.g.doubleclick.net/gampad/ads?sz=336x280|300x250|400x300|480x720|1024x768&iu=/24458126/O2A_Preroll_web&env=vp&impl=s&gdfp_req=1&output=vast&unviewed_position_start=1&url=https://12all.tv&description_url=https://12all.tv&correlator=${now}`;

      adsRequest.linearAdSlotWidth = videoElement.clientWidth;
      adsRequest.linearAdSlotHeight = videoElement.clientHeight;
      adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
      adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;

      adsRequest.setAdWillAutoPlay(true);
      adsRequest.setContinuousPlayback(true);

      console.log("Requesting ads...");
      adsLoaderRef.current.requestAds(adsRequest);
    };

    const onAdsManagerLoaded = (adsManagerLoadedEvent: any) => {
      console.log("AdsManager loaded event received.");
      adsManagerRef.current = adsManagerLoadedEvent.getAdsManager(
        videoRef.current
      );

      adsManagerRef.current.addEventListener(
        window.google.ima.AdErrorEvent.Type.AD_ERROR,
        onAdError
      );
      adsManagerRef.current.addEventListener(
        window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
        onContentPauseRequested
      );
      adsManagerRef.current.addEventListener(
        window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
        onContentResumeRequested
      );

      adsManagerRef.current.addEventListener(
        window.google.ima.AdEvent.Type.CLICK,
        onAdClick,
        false
      );

      // // Add an event listener for when all ads are completed
      // adsManagerRef.current.addEventListener(
      //   window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
      //   onAllAdsCompleted
      // );

      adsManagerRef.current.addEventListener(
        window.google.ima.AdEvent.Type.SKIPPED,
        onAdSkipped
      );
      adsManagerRef.current.addEventListener(
        window.google.ima.AdEvent.Type.STARTED,
        onAdStarted
      );

      setAdsLoaded(true);

      try {
        adsManagerRef.current.init(
          videoRef.current!.clientWidth,
          videoRef.current!.clientHeight,
          window.google.ima.ViewMode.NORMAL
        );
        adsManagerRef.current.start();
        console.log("AdsManager started.");

        adsManagerRef.current.setVolume(isMuted ? 0 : 1);
      } catch (adError) {
        console.error("AdsManager could not be started:", adError);
      }
    };

    const onAdClick = () => {
      console.log("Ad clicked, opening link in new tab.");
      // Prevent pausing or stopping the video when ad is clicked
      adsManagerRef.current.resume();
    };

    const onAdError = (adErrorEvent: any) => {
      console.error("Ad error:", adErrorEvent.getError());
      adsManagerRef.current?.destroy();
    };

    const onContentPauseRequested = () => {
      console.log("Content pause requested.");
      videoRef.current?.pause();
    };

    const onContentResumeRequested = () => {
      console.log("Content resume requested.");
      // Prevent the content from resuming after ads
      adsManagerRef.current.resume();
    };

    const onAllAdsCompleted = () => {
      console.log("All ads completed. Restarting ads.");
      adsManagerRef.current?.destroy(); // Destroy current ad manager
      initIMA(); // Re-initialize IMA SDK to request ads again
    };

    const onAdSkipped = () => {
      console.log("Ad was skipped.");
      adsManagerRef.current?.destroy();
      setAdsLoaded(false);
    };

    const onAdStarted = () => {
      console.log("Ad has started playing.");
      setAdsLoaded(true); // Set adsStarted to true when the ad starts
    };

    const tryAutoplay = async () => {
      try {
        adsManagerRef.current.resume();
        console.log("Video autoplayed successfully");
      } catch (error) {
        console.warn(
          "Autoplay failed. User interaction required to start ads.",
          error
        );
      }
    };

    tryAutoplay(); // Attempt to autoplay the video
    initIMA(); // Initialize IMA SDK for ads

    return () => {
      adsManagerRef.current?.destroy();
    };
  }, []);

  const handleMuteToggle = () => {
    if (!adsManagerRef.current) return;

    const newMuteState = !isMuted;
    setIsMuted(newMuteState); // Toggle mute state
    adsManagerRef.current.setVolume(newMuteState ? 0 : 1); // Mute/Unmute based on state
  };

  if (adsBlocked) {
    // Fallback if ads are blocked
    return (
      <div className="ads-container">
        <video id="video-player" ref={videoRef} />
        <p style={{ zIndex: 201, color: "white" }}>
          Ads could not be displayed due to ad blocker. Continuing video without
          ads.
        </p>
      </div>
    );
  }

  return (
    <div className="ads-container">
      <div id="ad-container" className={className}></div>
      <video id="video-player" ref={videoRef} />
      {adsLoaded && (
        <button onClick={handleMuteToggle} id="mute-btn">
          {isMuted ? "Unmute Ads" : "Mute Ads"}
        </button>
      )}
    </div>
  );
};

export default GoogleAdStream;




// import React, { useEffect, useRef, useState } from "react";
// import "./styles.scss";

// // Define any custom types needed for the Google IMA SDK
// declare global {
//   interface Window {
//     google: any;
//   }
// }

// const GoogleAdStream: React.FC<{ className?: string }> = ({ className }) => {
//   const videoRef = useRef<HTMLVideoElement | null>(null);
//   const adsLoaderRef = useRef<any>(null);
//   const adsManagerRef = useRef<any>(null);
//   const adDisplayContainerRef = useRef<any>(null);
//   const [isMuted, setIsMuted] = useState<boolean>(false);
//   const [adsBlocked, setAdsBlocked] = useState<boolean>(false);
//   const [adsLoaded, setAdsLoaded] = useState<boolean>(false);
//   const [adError, setAdError] = useState<string | null>(null);

//   useEffect(() => {
//     // Load IMA SDK script if not already loaded

//     const initIMA = () => {
//       const videoElement = videoRef.current;

//       if (!videoElement) {
//         console.error("Video element not found");
//         return;
//       }

//       if (!window.google || !window.google.ima) {
//         console.warn(
//           "Google IMA SDK is blocked by an ad blocker or not available."
//         );
//         setAdsBlocked(true); // Set state to handle ad blocker case
//         return;
//       }

//       // Create the ad display container
//       adDisplayContainerRef.current = new window.google.ima.AdDisplayContainer(
//         document.getElementById("ad-container"),
//         videoElement
//       );

//       // Initialize the ad display container (may require user interaction in some cases)
//       adDisplayContainerRef.current.initialize();

//       // Create ads loader
//       adsLoaderRef.current = new window.google.ima.AdsLoader(
//         adDisplayContainerRef.current
//       );

//       // Add ads loader event listeners
//       adsLoaderRef.current.addEventListener(
//         window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
//         onAdsManagerLoaded,
//         false
//       );
//       adsLoaderRef.current.addEventListener(
//         window.google.ima.AdErrorEvent.Type.AD_ERROR,
//         onAdError,
//         false
//       );

//       // Request video ads
//       const adsRequest = new window.google.ima.AdsRequest();
//       const now = new Date().getTime();
      
//       // Try a different ad tag URL that's more likely to return ads
//       // You can also try using a test ad tag from Google
//       adsRequest.adTagUrl = `https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_ad_samples&sz=640x480&cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=${now}`;

//       adsRequest.linearAdSlotWidth = videoElement.clientWidth || 640;
//       adsRequest.linearAdSlotHeight = videoElement.clientHeight || 360;
//       adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth || 640;
//       adsRequest.nonLinearAdSlotHeight = (videoElement.clientHeight || 360) / 3;

//       // Set additional parameters to improve ad loading
//       adsRequest.setAdWillAutoPlay(true);
//       adsRequest.setAdWillPlayMuted(false);
//       adsRequest.setContinuousPlayback(true);

//       console.log("Requesting ads...");
//       adsLoaderRef.current.requestAds(adsRequest);
//     };
    
//     if (!document.getElementById('ima-sdk-script')) {
//       const script = document.createElement('script');
//       script.id = 'ima-sdk-script';
//       script.src = 'https://imasdk.googleapis.com/js/sdkloader/ima3.js';
//       script.async = true;
//       script.onload = () => initIMA();
//       document.body.appendChild(script);
//     } else {
//       setTimeout(initIMA, 0);
//     }

//     const onAdsManagerLoaded = (adsManagerLoadedEvent: any) => {
//       console.log("AdsManager loaded event received.");
//       setAdError(null); // Clear any previous errors
      
//       try {
//         adsManagerRef.current = adsManagerLoadedEvent.getAdsManager(
//           videoRef.current
//         );

//         // Add event listeners
//         adsManagerRef.current.addEventListener(
//           window.google.ima.AdErrorEvent.Type.AD_ERROR,
//           onAdError
//         );
//         adsManagerRef.current.addEventListener(
//           window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
//           onContentPauseRequested
//         );
//         adsManagerRef.current.addEventListener(
//           window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
//           onContentResumeRequested
//         );
//         adsManagerRef.current.addEventListener(
//           window.google.ima.AdEvent.Type.CLICK,
//           onAdClick,
//           false
//         );
//         adsManagerRef.current.addEventListener(
//           window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
//           onAllAdsCompleted
//         );
//         adsManagerRef.current.addEventListener(
//           window.google.ima.AdEvent.Type.SKIPPED,
//           onAdSkipped
//         );
//         adsManagerRef.current.addEventListener(
//           window.google.ima.AdEvent.Type.STARTED,
//           onAdStarted
//         );

//         setAdsLoaded(true);

//         // Initialize and start the ads manager
//         adsManagerRef.current.init(
//           videoRef.current!.clientWidth || 640,
//           videoRef.current!.clientHeight || 360,
//           window.google.ima.ViewMode.NORMAL
//         );
//         adsManagerRef.current.start();
//         console.log("AdsManager started.");

//         adsManagerRef.current.setVolume(isMuted ? 0 : 1);
//       } catch (adError) {
//         console.error("AdsManager could not be started:", adError);
//         setAdError("Failed to start ad manager");
//       }
//     };

//     const onAdClick = () => {
//       console.log("Ad clicked, opening link in new tab.");
//       // Prevent pausing or stopping the video when ad is clicked
//       adsManagerRef.current?.resume();
//     };

//     const onAdError = (adErrorEvent: any) => {
//       const error = adErrorEvent.getError();
//       console.error("Ad error:", error);
      
//       // Log detailed error information
//       if (error.data) {
//         console.error(`Error code: ${error.data.errorCode}, Message: ${error.data.errorMessage}`);
//         setAdError(`Ad Error: ${error.data.errorMessage}`);
//       }
      
//       // Clean up and try to recover
//       adsManagerRef.current?.destroy();
//       setAdsLoaded(false);
      
//       // If it's a VAST error 303, we could try to request a different ad
//       if (error.data && error.data.errorCode === 303) {
//         console.log("No ads available, trying fallback content");
//         // You could implement a fallback ad request here
//       }
//     };

//     const onContentPauseRequested = () => {
//       console.log("Content pause requested.");
//       videoRef.current?.pause();
//     };

//     const onContentResumeRequested = () => {
//       console.log("Content resume requested.");
//       // Prevent the content from resuming after ads
//       adsManagerRef.current?.resume();
//     };

//     const onAllAdsCompleted = () => {
//       console.log("All ads completed. Restarting ads.");
//       adsManagerRef.current?.destroy(); // Destroy current ad manager
//       initIMA(); // Re-initialize IMA SDK to request ads again
//     };

//     const onAdSkipped = () => {
//       console.log("Ad was skipped.");
//       adsManagerRef.current?.destroy();
//       setAdsLoaded(false);
//     };

//     const onAdStarted = () => {
//       console.log("Ad has started playing.");
//       setAdsLoaded(true); // Set adsStarted to true when the ad starts
//     };

//     const tryAutoplay = async () => {
//       try {
//         if (adsManagerRef.current) {
//           adsManagerRef.current.resume();
//           console.log("Video autoplayed successfully");
//         }
//       } catch (error) {
//         console.warn(
//           "Autoplay failed. User interaction required to start ads.",
//           error
//         );
//       }
//     };

//     // Clean up function
//     return () => {
//       if (adsManagerRef.current) {
//         try {
//           adsManagerRef.current.destroy();
//         } catch (e) {
//           console.error("Error destroying ads manager:", e);
//         }
//       }
      
//       if (adsLoaderRef.current) {
//         try {
//           adsLoaderRef.current.destroy();
//         } catch (e) {
//           console.error("Error destroying ads loader:", e);
//         }
//       }
//     };
//   }, [isMuted]); // Added isMuted as a dependency

//   const handleMuteToggle = () => {
//     if (!adsManagerRef.current) return;

//     const newMuteState = !isMuted;
//     setIsMuted(newMuteState); // Toggle mute state
//     adsManagerRef.current.setVolume(newMuteState ? 0 : 1); // Mute/Unmute based on state
//   };

//   if (adsBlocked) {
//     // Fallback if ads are blocked
//     return (
//       <div className="ads-container">
//         <video id="video-player" ref={videoRef} />
//         <p style={{ zIndex: 201, color: "white" }}>
//           Ads could not be displayed due to ad blocker. Continuing without ads.
//         </p>
//       </div>
//     );
//   }

//   return (
//     <div className="ads-container">
//       <div id="ad-container" className={className}></div>
//       <video id="video-player" ref={videoRef} />
//       {adError && (
//         <p style={{ zIndex: 201, color: "white", background: "rgba(0,0,0,0.7)", padding: "8px" }}>
//           {adError}
//         </p>
//       )}
//       {adsLoaded && (
//         <button onClick={handleMuteToggle} id="mute-btn">
//           {isMuted ? "Unmute Ads" : "Mute Ads"}
//         </button>
//       )}
//     </div>
//   );
// };

// export default GoogleAdStream;


// // import React, { useEffect, useRef, useState } from "react";
// // import "./styles.scss";

// // // Define any custom types needed for the Google IMA SDK
// // declare global {
// //   interface Window {
// //     google: any;
// //   }
// // }

// // const GoogleAdStream: React.FC<{ className?: string }> = ({ className }) => {
// //   const videoRef = useRef<HTMLVideoElement | null>(null);
// //   const adsLoaderRef = useRef<any>(null);
// //   const adsManagerRef = useRef<any>(null);
// //   const adDisplayContainerRef = useRef<any>(null);
// //   const [isMuted, setIsMuted] = useState<boolean>(false);
// //   const [adsBlocked, setAdsBlocked] = useState<boolean>(false);
// //   const [adsLoaded, setAdsLoaded] = useState<boolean>(false);

// //   useEffect(() => {
// //     const initIMA = () => {
// //       const videoElement = videoRef.current;

// //       if (!videoElement) {
// //         console.error("Video element not found");
// //         return;
// //       }

// //       if (!window.google || !window.google.ima) {
// //         console.warn(
// //           "Google IMA SDK is blocked by an ad blocker or not available."
// //         );
// //         setAdsBlocked(true); // Set state to handle ad blocker case
// //         return;
// //       }

// //       // Create the ad display container
// //       adDisplayContainerRef.current = new window.google.ima.AdDisplayContainer(
// //         document.getElementById("ad-container"),
// //         videoElement
// //       );

// //       // Initialize the ad display container (may require user interaction in some cases)
// //       adDisplayContainerRef.current.initialize();

// //       // Create ads loader
// //       adsLoaderRef.current = new window.google.ima.AdsLoader(
// //         adDisplayContainerRef.current
// //       );

// //       // Add ads loader event listeners
// //       adsLoaderRef.current.addEventListener(
// //         window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
// //         onAdsManagerLoaded,
// //         false
// //       );
// //       adsLoaderRef.current.addEventListener(
// //         window.google.ima.AdErrorEvent.Type.AD_ERROR,
// //         onAdError,
// //         false
// //       );

// //       // Request video ads
// //       const adsRequest = new window.google.ima.AdsRequest();
// //       const now = new Date().getTime();
// //       adsRequest.adTagUrl = `https://pubads.g.doubleclick.net/gampad/ads?sz=336x280|300x250|400x300|480x720|1024x768&iu=/24458126/O2A_Preroll_web&env=vp&impl=s&gdfp_req=1&output=vast&unviewed_position_start=1&url=https://12all.tv&description_url=https://12all.tv&correlator=${now}`;

// //       adsRequest.linearAdSlotWidth = videoElement.clientWidth;
// //       adsRequest.linearAdSlotHeight = videoElement.clientHeight;
// //       adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
// //       adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;

// //       adsRequest.setAdWillAutoPlay(true);
// //       adsRequest.setContinuousPlayback(true);

// //       console.log("Requesting ads...");
// //       adsLoaderRef.current.requestAds(adsRequest);
// //     };

// //     const onAdsManagerLoaded = (adsManagerLoadedEvent: any) => {
// //       console.log("AdsManager loaded event received.");
// //       adsManagerRef.current = adsManagerLoadedEvent.getAdsManager(
// //         videoRef.current
// //       );

// //       adsManagerRef.current.addEventListener(
// //         window.google.ima.AdErrorEvent.Type.AD_ERROR,
// //         onAdError
// //       );
// //       adsManagerRef.current.addEventListener(
// //         window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
// //         onContentPauseRequested
// //       );
// //       adsManagerRef.current.addEventListener(
// //         window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED,
// //         onContentResumeRequested
// //       );

// //       adsManagerRef.current.addEventListener(
// //         window.google.ima.AdEvent.Type.CLICK,
// //         onAdClick,
// //         false
// //       );

// //       // // Add an event listener for when all ads are completed
// //       // adsManagerRef.current.addEventListener(
// //       //   window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
// //       //   onAllAdsCompleted
// //       // );

// //       adsManagerRef.current.addEventListener(
// //         window.google.ima.AdEvent.Type.SKIPPED,
// //         onAdSkipped
// //       );
// //       adsManagerRef.current.addEventListener(
// //         window.google.ima.AdEvent.Type.STARTED,
// //         onAdStarted
// //       );

// //       setAdsLoaded(true);

// //       try {
// //         adsManagerRef.current.init(
// //           videoRef.current!.clientWidth,
// //           videoRef.current!.clientHeight,
// //           window.google.ima.ViewMode.NORMAL
// //         );
// //         adsManagerRef.current.start();
// //         console.log("AdsManager started.");

// //         adsManagerRef.current.setVolume(isMuted ? 0 : 1);
// //       } catch (adError) {
// //         console.error("AdsManager could not be started:", adError);
// //       }
// //     };

// //     const onAdClick = () => {
// //       console.log("Ad clicked, opening link in new tab.");
// //       // Prevent pausing or stopping the video when ad is clicked
// //       adsManagerRef.current.resume();
// //     };

// //     const onAdError = (adErrorEvent: any) => {
// //       console.error("Ad error:", adErrorEvent.getError());
// //       adsManagerRef.current?.destroy();
// //     };

// //     const onContentPauseRequested = () => {
// //       console.log("Content pause requested.");
// //       videoRef.current?.pause();
// //     };

// //     const onContentResumeRequested = () => {
// //       console.log("Content resume requested.");
// //       // Prevent the content from resuming after ads
// //       adsManagerRef.current.resume();
// //     };

// //     const onAllAdsCompleted = () => {
// //       console.log("All ads completed. Restarting ads.");
// //       adsManagerRef.current?.destroy(); // Destroy current ad manager
// //       initIMA(); // Re-initialize IMA SDK to request ads again
// //     };

// //     const onAdSkipped = () => {
// //       console.log("Ad was skipped.");
// //       adsManagerRef.current?.destroy();
// //       setAdsLoaded(false);
// //     };

// //     const onAdStarted = () => {
// //       console.log("Ad has started playing.");
// //       setAdsLoaded(true); // Set adsStarted to true when the ad starts
// //     };

// //     const tryAutoplay = async () => {
// //       try {
// //         adsManagerRef.current.resume();
// //         console.log("Video autoplayed successfully");
// //       } catch (error) {
// //         console.warn(
// //           "Autoplay failed. User interaction required to start ads.",
// //           error
// //         );
// //       }
// //     };

// //     tryAutoplay(); // Attempt to autoplay the video
// //     initIMA(); // Initialize IMA SDK for ads

// //     return () => {
// //       adsManagerRef.current?.destroy();
// //     };
// //   }, []);

// //   const handleMuteToggle = () => {
// //     if (!adsManagerRef.current) return;

// //     const newMuteState = !isMuted;
// //     setIsMuted(newMuteState); // Toggle mute state
// //     adsManagerRef.current.setVolume(newMuteState ? 0 : 1); // Mute/Unmute based on state
// //   };

// //   if (adsBlocked) {
// //     // Fallback if ads are blocked
// //     return (
// //       <div className="ads-container">
// //         <video id="video-player" ref={videoRef} />
// //         <p style={{ zIndex: 201, color: "white" }}>
// //           Ads could not be displayed due to ad blocker. Continuing video without
// //           ads.
// //         </p>
// //       </div>
// //     );
// //   }

// //   return (
// //     <div className="ads-container">
// //       <div id="ad-container" className={className}></div>
// //       <video id="video-player" ref={videoRef} />
// //       {adsLoaded && (
// //         <button onClick={handleMuteToggle} id="mute-btn">
// //           {isMuted ? "Unmute Ads" : "Mute Ads"}
// //         </button>
// //       )}
// //     </div>
// //   );
// // };

// // export default GoogleAdStream;
