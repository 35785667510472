import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import {
  IonAvatar,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuButton,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonToolbar,
  useIonRouter,
  // useIonRouter
} from "@ionic/react";
import { globeOutline, personCircleOutline } from "ionicons/icons";
import headerLogo from "../../images/12all-header.png";
import { ILanguage, LANGUAGES } from "../../shared/Language";
import { useHistory, useLocation } from "react-router";
import { Routes } from "../../shared/routes";
import { useDispatch, useSelector } from "react-redux";
import { ReduxSelectors } from "../../redux/shared/types";
import { setLogout } from "../../redux/actions/profileActions";
import appStorage, { StorageKey } from "../../shared/appStorage";
import { setInfoToast } from "../../redux/actions/toastActions";
import setLanguage from "../../redux/actions/languageActions";
import i18n from "../../i18n";
import { MAIN_CONTENT_ID } from "../../shared/constants";
import HeaderSearchbar from "../HeaderSearchbar";
import redSharpStar from "../../images/icons/star-sharp.svg";
import { setTotalStarBalance } from "../../redux/actions/billingRewardActions";

const APP_LNG = "appLng";

// billing:
type starsType = {
  [key: string]: string;
  howToWin: string;
  inviteAndWin: string;
  shop: string;
  starsStatus: string;
  starsTransaction: string;
  // cashOut: string
};

type starsTypeVisible = {
  [key: string]: boolean;
  howToWin: boolean;
  inviteAndWin: boolean;
  shop: boolean;
  starsStatus: boolean;
  starsTransaction: boolean;
  // cashOut: boolean
};

type MenuItemsProps = {
  inToolbar?: boolean;
  onChangeLanguage: (language: ILanguage) => void;
  onProfileClick: (event: any) => void;
};

const Logo: FC = () => (
    <IonItem
        routerLink={Routes.Home}
        routerDirection="back"
        lines="none"
        detail={false}
    >
      <IonImg src={headerLogo} />
    </IonItem>
);

const MenuItems: FC<MenuItemsProps> = ({
                                         inToolbar,
                                         onChangeLanguage,
                                         onProfileClick,
                                       }: MenuItemsProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const router = useIonRouter();
  const history = useHistory();
  const profile = useSelector(({ profile }: ReduxSelectors) => profile);
  const language = useSelector(({ language }: ReduxSelectors) => language);
  const { starsBalance } = useSelector(
      ({ billingRewards }: ReduxSelectors) => billingRewards
  );
  const lines = useRef<"none" | "full">(inToolbar ? "none" : "full");
  const [activeRoute, setActiveRoute] = useState<string>("");
  const [selectedStar, setSelectedStar] = useState<string>("");

  // billing:
  const starsObj: starsType = {
    howToWin: "HOW TO WIN STARS",
    inviteAndWin: "INVITE AND WIN",
    shop: "SHOP",
    starsStatus: "STARS STATUS",
    starsTransaction: "STARS TRANSACTIONS",
    // cashOut: "CASH OUT"
  };

  const starsObjAuthVisible: starsTypeVisible = {
    howToWin: false,
    inviteAndWin: true,
    shop: true,
    starsStatus: true,
    starsTransaction: true,
    // cashOut: true
  };

  useEffect(() => {
    setActiveRoute(pathname);
  }, [pathname]);

  const manageLanguageChange = (e: CustomEvent) => {
    const language = LANGUAGES.find((l) => l.key === e.detail.value);
    if (language) {
      onChangeLanguage(language);
      appStorage.setItem(APP_LNG, language.key);
    }
  };

  // billing:
  const selectStars = (e: CustomEvent) => {
    console.log("e", e.detail.value);
    switch (e.detail.value) {
      case "howToWin":
        setSelectedStar(e.detail.value);
        // window.location.href = Routes.howToWin;
        history.push(Routes.howToWin);
        break;
      case "inviteAndWin":
        setSelectedStar(e.detail.value);
        router.push(Routes.InviteAndWin);
        break;
      case "shop":
        setSelectedStar(e.detail.value);
        router.push(Routes.Shop);
        break;
      case "starsStatus":
        setSelectedStar(e.detail.value);
        router.push(Routes.StarsStatusTable);
        break;
      case "starsTransaction":
        setSelectedStar(e.detail.value);
        router.push(Routes.StarsTransaction);
        break;
        // case "cashOut":
        //   setSelectedStar(e.detail.value)
        //   router.push(Routes.CashOut);
        //   break;
      default:
        setSelectedStar("");
        break;
    }
  };

  const setItemAsActive = (routeName: string) => {
    return `${activeRoute === routeName ? "active" : ""}`;
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (selectedStar) {
        setSelectedStar("");
      }
    });

    return () => {
      unlisten();
    };
  }, [history, selectedStar]);

  const dispatch = useDispatch();

  return (
      <>
        <IonItem
            routerLink={Routes.Home}
            className={setItemAsActive(Routes.Home)}
            // routerDirection="back"
            lines={lines.current}
        >
          <IonLabel>{t("nav.home")}</IonLabel>
        </IonItem>

        <IonItem
            routerLink={Routes.About}
            className={setItemAsActive(Routes.About)}
            // routerDirection="back"
            lines={lines.current}
        >
          <IonLabel>{t("nav.about")}</IonLabel>
        </IonItem>

        {/* <IonItem routerLink={Routes.Shop} routerDirection="back" lines={lines}>
          <IonLabel color={setItemColor(Routes.Shop)}>{t('nav.shop')}</IonLabel>
        </IonItem> */}

        <IonItem
            routerLink={Routes.Downloads}
            className={setItemAsActive(Routes.Downloads)}
            // routerDirection="back"
            lines={lines.current}
        >
          <IonLabel>{t("nav.downloads")}</IonLabel>
        </IonItem>

        <IonItem
            routerLink={Routes.News}
            className={setItemAsActive(Routes.News)}
            // routerDirection="back"
            lines={lines.current}
        >
          <IonLabel>{t("nav.news")}</IonLabel>
        </IonItem>

        {/* {profile.jwt && !profile.isAnonymous && <IonItem lines={lines.current} data-id="stars" className="stars-item">
        <IonLabel>{t('nav.stars')}</IonLabel>
        <IonSelect
          value={selectedStar}
          // selectedText={selectedStar}
          onIonChange={selectStars}
          interface="popover"
          data-id="language"
        >
          {Object.keys(starsObj).map((key) => (
            <IonSelectOption key={key} value={key}>
              {starsObj[key]}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>} */}

        {/* billing: */}
        {profile.jwt && !profile.isAnonymous && (
            <IonItem
                routerLink={Routes.Premium}
                className={setItemAsActive(Routes.Premium)}
                // routerDirection="back"
                lines={lines.current}
            >
              <IonLabel>{t("nav.premium")}</IonLabel>
            </IonItem>
        )}

        <IonItem
            routerLink={Routes.Support}
            className={setItemAsActive(Routes.Support)}
            // routerDirection="back"
            lines={lines.current}
        >
          <IonLabel>{t("nav.support")}</IonLabel>
        </IonItem>

        <HeaderSearchbar />

        <IonItem
            lines={lines.current}
            data-id="language"
            className="language-item"
        >
          <IonIcon icon={globeOutline} color="dark" data-id="language" />
          <IonSelect
              value={language.key}
              selectedText={language.initial}
              onIonChange={manageLanguageChange}
              interface="popover"
              data-id="language"
          >
            {LANGUAGES.map(({ key, name }: ILanguage) => (
                <IonSelectOption key={key} value={key}>
                  {name}
                </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>

        {profile.jwt ? (
            <IonItem
                lines="none"
                className="profile-button"
                button
                onClick={onProfileClick}
                data-id="profile"
            >
              {profile.avatar ? (
                  <IonAvatar slot="start" data-id="profile">
                    <img alt="" src={`${profile.avatar}`} data-id="profile" />
                  </IonAvatar>
              ) : (
                  <IonIcon
                      icon={personCircleOutline}
                      color="dark"
                      data-id="profile"
                  />
              )}

              <IonLabel data-id="profile">
                {profile.isAnonymous
                    ? "User"
                    : profile.nickname
                        ? profile.nickname
                        : (!!profile.email && profile.email.includes("@12all.anon"))
                            ? profile.phoneNumber
                            : profile.email}
              </IonLabel>
            </IonItem>
        ) : (
            <IonItem
                routerLink={Routes.Login}
                className={`login-item ${setItemAsActive(Routes.Login)}`}
                // routerDirection="back"
                lines="none"
            >
              <IonIcon icon={personCircleOutline} color="dark" />
              <IonLabel>{t("nav.login")}</IonLabel>
            </IonItem>
        )}

        {/* billing: */}
        <IonItem
            lines={lines.current}
            data-id="stars"
            className="star-balance-display stars-item"
        >
          <IonImg src={redSharpStar} />
          <IonLabel>{`${ profile.jwt &&
          !profile.isAnonymous &&
          ((!!profile?.email && !profile?.email.includes("@skiplogin.com")) || profile?.email === null) ? starsBalance : "Stars"}`}</IonLabel>
          <IonSelect
              value={selectedStar}
              onIonChange={selectStars}
              interface="popover"
              data-id="language"
          >
            {Object.keys(starsObj).map((key) =>
                profile.jwt &&
                !profile.isAnonymous &&
                ((!!profile?.email && !profile?.email.includes("@skiplogin.com")) || profile?.email === null)
                 ? (
                    <IonSelectOption key={key} value={key}>
                      {starsObj[key]}
                    </IonSelectOption>
                ) : (
                    starsObjAuthVisible[key] === false && (
                        <IonSelectOption key={key} value={key}>
                          {starsObj[key]}
                        </IonSelectOption>
                    )
                )
            )}
          </IonSelect>
        </IonItem>
      </>
  );
};

const Header: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const language = useSelector(({ language }: ReduxSelectors) => language);
  const menuRef = useRef<HTMLIonMenuElement>(null);

  const [profilePopover, setProfilePopover] = useState<{
    showPopover: boolean;
    event?: Event;
  }>({ showPopover: false });

  const profile = useSelector(({ profile }: ReduxSelectors) => profile);

  const handleChangeLanguage = useCallback(
      (lng: ILanguage) => {
        document.documentElement.dir = lng.dir;
        i18n.changeLanguage(lng.key).then();
        dispatch(setLanguage(lng));
      },
      [dispatch]
  );

  useEffect(() => {
    const value = appStorage.getItem(APP_LNG);
    if (value) {
      const lng = LANGUAGES.find((l) => l.key === value);
      if (lng) {
        handleChangeLanguage(lng);
      }
    }
  }, [handleChangeLanguage]);

  const onWindowResize = () => {
    if (window.innerWidth > 992 && menuRef.current) {
      menuRef.current.close().then();
    }
  };

  const menuDidOpen = () => {
    window.addEventListener("resize", onWindowResize);
  };

  const menuDidClose = () => {
    window.removeEventListener("resize", onWindowResize);
  };

  const logout = () => {
    dispatch(setLogout());
    appStorage.removeItem(StorageKey.Login);
    setProfilePopover({ showPopover: false });
    // delay preventing error on redirect
    history.replace(Routes.Login);
    setTimeout(() => {
      dispatch(setInfoToast("logout.logoutSuccess"));
    }, 500);
  };

  const anonymousLogoutLogin = () => {
    dispatch(setLogout());
    appStorage.removeItem(StorageKey.Login);
    setProfilePopover({ showPopover: false });

    history.replace(Routes.Login);
  };

  const handleProfileClick = (event: any) => {
    event.persist();
    setProfilePopover({ showPopover: true, event });
  };

  const handleMenuListClick = (e: React.MouseEvent<HTMLIonListElement>) => {
    // @ts-ignore
    if (!e.target.dataset.id) {
      menuRef.current?.close();
    }
  };

  return (
      <>
        <IonHeader className="app-header">
          <IonToolbar className="header-toolbar">
            <IonItem className="app-header-toggle-menu" lines="none">
              <IonMenuButton
                  color="primary"
                  onClick={() => menuRef.current?.toggle()}
                  autoHide={false}
              />
              <Logo />
            </IonItem>
            <IonItemGroup className="nav-container">
              <Logo />
              <IonItemGroup>
                <MenuItems
                    inToolbar
                    onChangeLanguage={handleChangeLanguage}
                    onProfileClick={handleProfileClick}
                />
              </IonItemGroup>
            </IonItemGroup>
          </IonToolbar>

          <IonPopover
              event={profilePopover.event}
              isOpen={profilePopover.showPopover}
              onDidDismiss={() => setProfilePopover({ showPopover: false })}
          >
            <IonList>
              {profile.jwt &&
              !profile.isAnonymous &&
              ((!!profile?.email && !profile?.email.includes("@skiplogin.com")) || profile?.email === null) ? (
                  <>
                    <IonItem
                        routerLink={Routes.MyProfile}
                        onClick={() => setProfilePopover({ showPopover: false })}
                        // routerDirection="back"
                        lines="none"
                    >
                      {t("nav.myProfile")}
                    </IonItem>

                    <IonItem
                        routerLink={Routes.resetCode}
                        onClick={() => setProfilePopover({ showPopover: false })}
                        lines="none"
                    >
                      {t("nav.resetPassword")}
                    </IonItem>

                    {/* billing: */}
                    <IonItem
                        routerLink={Routes.AccountStatus}
                        onClick={() => {
                          setProfilePopover({ showPopover: false });
                          // history.replace(Routes.AccountStatus);
                        }}
                        // routerDirection="back"
                        lines="none"
                    >
                      {t("nav.accountStatus")}
                    </IonItem>
                  </>
              ) : <IonItem
                  button
                  onClick={() => {
                    setProfilePopover({ showPopover: false });
                    history.replace(Routes.Login + "?action=register")
                  }}
                  lines="none"
              >
                REGISTER USER
              </IonItem>}
              <IonItem
                  button
                  onClick={profile?.isAnonymous ? anonymousLogoutLogin : logout}
                  lines="none"
              >
                {profile?.isAnonymous ? t("nav.login") : t("nav.logout")}
              </IonItem>
            </IonList>
          </IonPopover>
        </IonHeader>
        <IonMenu
            contentId={MAIN_CONTENT_ID}
            class="app-menu"
            type="overlay"
            ref={menuRef}
            side={language.dir === "ltr" ? "start" : "end"}
            onIonDidOpen={menuDidOpen}
            onIonDidClose={menuDidClose}
            swipeGesture={false}
        >
          <IonContent>
            <IonList onClick={handleMenuListClick}>
              <MenuItems
                  onChangeLanguage={handleChangeLanguage}
                  onProfileClick={handleProfileClick}
              />
            </IonList>
          </IonContent>
        </IonMenu>
      </>
  );
};

export default Header;
