import {
  ADD_GAME,
  SET_GAMES,
  MANAGE_GAME
} from '../shared/constants';
import { BetGameObj } from '../../shared/types';

export function addGame(value: BetGameObj) {
  return {
    type: ADD_GAME,
    payload: value
  };
}

export function setGames(value: BetGameObj[]) {
  return {
    type: SET_GAMES,
    payload: value
  };
}

export function manageGames(value: BetGameObj) {
  return {
    type: MANAGE_GAME,
    payload: value
  };
}
