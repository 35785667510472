import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import {
  IonAvatar, IonCard, IonCardContent, IonCardHeader,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuButton,
  IonPopover,
  IonSelect,
  IonSelectOption, IonText,
  IonToolbar,
  useIonRouter,
  // useIonRouter
} from "@ionic/react";
import { globeOutline, personCircleOutline } from "ionicons/icons";
import headerLogo from "../../images/12all-header.png";
import { ILanguage, LANGUAGES } from "../../shared/Language";
import { useHistory, useLocation } from "react-router";
import { Routes } from "../../shared/routes";
import { useDispatch, useSelector } from "react-redux";
import { ReduxSelectors } from "../../redux/shared/types";
import { setLogout } from "../../redux/actions/profileActions";
import appStorage, { StorageKey } from "../../shared/appStorage";
import {setErrorToast, setInfoToast} from "../../redux/actions/toastActions";
import setLanguage from "../../redux/actions/languageActions";
import i18n from "../../i18n";
import { BILLING_SOCKET, MAIN_CONTENT_ID } from "../../shared/constants";
import HeaderSearchbar from "../HeaderSearchbar";
import redSharpStar from "../../images/icons/star-sharp.svg";
import { setTotalStarBalance } from "../../redux/actions/billingRewardActions";
import {BillingServices, UserManagementService} from "../../services";

type MenuItemsProps = {
  inToolbar?: boolean;
};

const MenuItems: FC<MenuItemsProps> = ({
                                         inToolbar,
                                       }: MenuItemsProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const router = useIonRouter();
  const history = useHistory();
  const profile = useSelector(({ profile }: ReduxSelectors) => profile);
  const language = useSelector(({ language }: ReduxSelectors) => language);
  const { starsBalance } = useSelector(
      ({ billingRewards }: ReduxSelectors) => billingRewards
  );
  const lines = useRef<"none" | "full">(inToolbar ? "none" : "full");
  const [activeRoute, setActiveRoute] = useState<string>("");
  const [selectedStar, setSelectedStar] = useState<string>("");

  useEffect(() => {
    setActiveRoute(pathname);
  }, [pathname]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (selectedStar) {
        setSelectedStar("");
      }
    });

    return () => {
      unlisten();
    };
  }, [history, selectedStar]);

  const dispatch = useDispatch();

  return (
      <>
        {/* billing: */}
        <IonItem
            lines={lines.current}
            data-id="stars"
            className="star-balance-display stars-item"
        >
          <IonImg src={redSharpStar} />
          <IonLabel>{`${ profile.jwt &&
          !profile.isAnonymous &&
          ((!!profile?.email && !profile?.email.includes("@skiplogin.com")) || profile?.email === null) ? starsBalance : "Stars"}`}</IonLabel>
        </IonItem>
      </>
  );
};

const Header: FC = () => {

  const dispatch = useDispatch();

  const [timeLeft, setTimeLeft] = useState<number>(0);
  const profile = useSelector(({ profile }: ReduxSelectors) => profile);

  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [messages, setMessages] = useState<any>([]);

  const [connectionTime, setConnectionTime] = useState<Date | null>(null);
  const [futureTime, setFutureTime] = useState<Date | null>(null);

  const [timer, setTimer] = useState<number | null>(null);

  useEffect(() => {
    async function billingEvent() {
      let billing = await BillingServices.getBillingEvents();
      if (billing.data.status === "ok") {
        const watchVideo = billing.data.result.find(item => item.type === "WATCH_VIDEO");
        const hostVideo = billing.data.result.find(item => item.type === "HOST_VIDEO");
        setTimer(watchVideo ? watchVideo.minutePeriod : null)
      }
      else {
        dispatch(setErrorToast(
            "Failed to load billing events"
        ))
      }
    }
    billingEvent();
  }, []);

  useEffect(() => {

    // Construct WebSocket URL with external client ID
    const wsUrl = `${BILLING_SOCKET}?externalClientId=${profile.id}`;

    // Create WebSocket connection
    const ws = new WebSocket(wsUrl);

    // Connection opened
    ws.onopen = () => {
      if(timer !== null) {
        console.log('::::::::::::::::::::::::::::::::: WebSocket connection established ::::::::::::::::::::::::::::::::: ');

        const now = new Date();
        setConnectionTime(now);

        // Calculate future timestamp (X minutes later)
        const future = new Date(now.getTime() + timer * 60000);
        setFutureTime(future);

        setSocket(ws as WebSocket | null);
      }
    };

    // Listen for messages
    ws.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        // Validate message structure
        if (message.stars && message.description) {
          setMessages((prevMessages: any[]) => [...prevMessages, message]);
          console.log(' ::::::::::::::::::::::::::::::::: Received message  ::::::::::::::::::::::::::::::::: ', message);
        } else {
          console.warn(' ::::::::::::::::::::::::::::::::: Invalid message format  ::::::::::::::::::::::::::::::::: ', message);
        }
      } catch (error) {
        console.error('Error parsing message:', error);
      }
    };

    // Handle connection errors
    ws.onerror = (error) => {
      console.error(' ::::::::::::::::::::::::::::::::: WebSocket error ::::::::::::::::::::::::::::::::: ', error);
    };

    // Handle connection close
    ws.onclose = (event) => {
      console.log(' ::::::::::::::::::::::::::::::::: WebSocket connection closed ::::::::::::::::::::::::::::::::: ', event);
    };

    // Cleanup function to close socket when component unmounts
    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [timer, profile.id]);

  useEffect(() => {
    console.log("::::::::::::::::::::::::::::::::: Timestamp ::::::::::::::::::::::::::::::::: ", connectionTime, futureTime, timeLeft > 0 ? timeLeft : 0);

    if (connectionTime && futureTime && timer) {
      const diffMs = futureTime.getTime() - new Date().getTime();
      let seconds = Math.floor(diffMs / 1000);

      if (seconds <= 0) {
        console.log("::::::::::::::::::::::::::::::::: Initial Timer Reset ::::::::::::::::::::::::::::::::: ");
        const now = new Date();
        setConnectionTime(now);
        const future = new Date(now.getTime() + timer * 60000);
        setFutureTime(future);
        setTimeLeft(timer * 60);
        return;
      }

      setTimeLeft(seconds); // Initialize timeLeft correctly

      const interval = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            console.log("::::::::::::::::::::::::::::::::: Timer Reset ::::::::::::::::::::::::::::::::: ");
            const now = new Date();
            setConnectionTime(now);
            const future = new Date(now.getTime() + timer * 60000);
            setFutureTime(future);
            return timer * 60; // Reset to X minutes in seconds
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [connectionTime, futureTime, timer]);


  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
      <>
        <IonHeader className="app-header-2">
          <IonToolbar className="header-toolbar">
            <IonItemGroup className={"nav-container flex !justify-between"}>

              <IonItemGroup className={"flex justify-start"}>
                <IonItem
                    data-id="reward"
                    className={timeLeft > 0 && timeLeft < 16 ? "star-balance-display stars-item" : "!hidden"}
                >
                  <IonImg src={redSharpStar} />
                  <IonLabel>Next reward in <b className={"ms-5"} style={{ color: "#ffab00" }}>{formatTime(timeLeft)}</b></IonLabel>
                </IonItem>
              </IonItemGroup>

              <IonItemGroup className="flex justify-end">
                <MenuItems
                    inToolbar
                />
              </IonItemGroup>
            </IonItemGroup>
          </IonToolbar>
        </IonHeader>
      </>
  );
};

export default Header;
