import { FC, FormEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { IonButton, IonList, IonSelect, IonSelectOption, IonModal, IonItem, IonInput, IonCol, IonRow, IonImg, IonText } from '@ionic/react';
import { useSelector } from 'react-redux';
import { ReduxSelectors } from '../../../../redux/shared/types';
import starSharp from '../../../../images/icons/star-sharp.svg';
import { BetGameObj, messageType, CHAT_MESSAGE_JOIN_GAME } from '../../../../shared/types';

interface JoinBetsModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  onSelect: (game: BetGameObj, type: messageType) => void;
}

const JoinBetModal: FC<JoinBetsModalProps> = ({show, setShow, onSelect}: JoinBetsModalProps) => {
  const {t} = useTranslation();
  const modalRef = useRef<HTMLIonModalElement>(null);
  const [selectedGame, setSelectedGame] = useState<number>(-1);
  const [step, setStep] = useState<number>(1);
  const [selectedGameObj, setSelectedGameObj] = useState<BetGameObj>();
  const [selectedChoiceText, setSelectedChoiceText] = useState<string>("");
  
  const { starsBalance } = useSelector(
      ({ billingRewards }: ReduxSelectors) => billingRewards
  );
  const gamesList = useSelector(({ betGames }: ReduxSelectors) => betGames.games);

  const onDismiss = () => {
    if (show) {
      setStep(1);
      setSelectedGame(-1);
      setShow(false);
    }
  };

  const customPopoverOptions: any = {
    cssClass: 'select-popover-bets',
  };

  const onSend = (e: FormEvent) => {
    e.preventDefault();

    setSelectedGame(-1);
    setStep(1);
    if (selectedGameObj) {
        onSelect(selectedGameObj, CHAT_MESSAGE_JOIN_GAME);
    }

    setShow(false);
  };

  const onChange = (name: string, value: string) => {
    let coinces = [];
    console.log(value);
  };

  const selectGame = (index: number) => {
    setSelectedGame(index);
    gamesList.map((game, gameIndex) => {
      if (index === gameIndex) {
        setSelectedGameObj(game);
      }
    })
  };

  const step2 = () => {
    setStep(2);
  };

  const selectedChoice = (choice: string) => {
    setSelectedChoiceText(choice);
  };

  return (
    <IonModal
      isOpen={show}
      ref={modalRef}
      className="bets-modal"
      onDidDismiss={onDismiss}
    >
      <div className='bets-join-modal-container'>
        <div className='user-stars-balance'> 
        Your Star balance: <IonImg src={starSharp} style={{ width: "22px", height: "22px", display: "inline-block", margin: "0 10px", verticalAlign: "sub" }} ></IonImg> { starsBalance }
        </div>
        { step === 1 &&         
        <div className='form-container'>
              AVAILABLE GAMES<br/><br/>
        <IonList className="bet-games-list-join" key="bet-games-list-join">
          { gamesList.map(
            ({ name, amount, status }, index) => (              
             ( status == 'active' &&
                <IonItem 
                  onClick={() => selectGame(index)}  
                  key={`join-game-list-${index}`}                 
                  >
                    <div className={`games-stars-balance${selectedGame === index ? " active" : ""}`}>
                      <div className='game-index'>{index + 1}. {name} </div>
                      <div>{ amount } <IonImg src={starSharp} style={{ width: "22px", height: "22px", display: "inline-block", margin: "0 10px", verticalAlign: "sub" }} ></IonImg> </div>               
                    </div>
                </IonItem>                    
              )
            )
          )}
          </IonList>
          <IonButton type="button" onClick={step2} disabled={selectedGame == -1}>{t('watchPartyStart.next')}</IonButton>                      
          {/* </form> */}
          </div>          
        }       

        { step === 2 &&         
        <div className='form-container'>
              {selectedGameObj?.createdByName} GAMES<br/>
        <form noValidate onSubmit={onSend} className='join-form'>
          <div>
          <div className='selected-game'>
            <span>{selectedGameObj?.name}</span><br/><br/>
            JOIN WITH<br/>
            <span>{selectedGameObj?.amount}</span> <IonImg src={starSharp} style={{ width: "18px", height: "18px", display: "inline-block", margin: "0 10px", verticalAlign: "sub" }} ></IonImg>
          </div>

          <IonRow className="rem-forget-col add-choice">
            <IonCol sizeXl="6" className="remember-me-col">
              <div className='value-score text-left'>Select Your answer</div>
              <IonSelect
                  key="jg-select-key"
                  value={selectedChoiceText}                    
                  interface="popover"
                  data-id="choice"
                  interfaceOptions={customPopoverOptions}
                  onIonChange={({ detail: { value } }) =>
                    setSelectedChoiceText(value ? value.trim() : "")
                  } 
              >
                {selectedGameObj?.choices.map((value: string) => (
                  <IonSelectOption key={`jg-key-${value}`} value={value}>
                    {value}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>
                <IonCol sizeXl="6" className="forgot-password-col">
                  <div className='value-score'>{selectedGameObj?.value} &nbsp;</div>
                  <IonItem>
                    <IonInput type="text"
                      inputMode="text"
                      name="value"
                      autocomplete="off"
                      value=""
                      placeholder='Enter value'
                      onIonChange={({ detail: { value } }) =>
                        onChange("value", value ? value.trim() : "")
                      }
                      required/>
                  </IonItem>
                
                </IonCol>
              </IonRow> 
              </div>
              <div>
                <IonButton type="submit">JOIN </IonButton>
                <IonButton type="button" color="light"  onClick={onDismiss}>{t('common.cancel')} </IonButton>                    
              </div>
        </form>
        </div>          
        }         
      </div>
    </IonModal>
  );
};

export default JoinBetModal;
