import {FC, useState, useEffect} from 'react';
import './styles.scss';
import {  
  IonButton,
  IonButtons,
  IonToolbar,
  IonImg
} from '@ionic/react';

import AddBetModal from '../AddBetModal';
import JoinBetModal from '../JoinBetModal'
import ManageBetModal from '../ManageBetModal';

import {useTranslation} from 'react-i18next';
import addGame from "../../../../images/icons/bets/add_game.png";
import joinGame from "../../../../images/icons/bets/join-game.png";
import manageGame from "../../../../images/icons/bets/manage-game.png";

import VertoSession from '../../../../verto/VertoSession';
import { BetGameObj, messageType } from '../../../../shared/types';
import { useSelector } from 'react-redux';
import { ReduxSelectors } from '../../../../redux/shared/types';

type Props = {
  isFullscreen: boolean;
  session: VertoSession;
  isRoomOwner: boolean | null;
};

const BetsBar: FC<Props> = (({isFullscreen, session, isRoomOwner }: Props) => {
  const {t} = useTranslation();

  const [showAddBetsModal, setShowAddBetsModal] = useState<boolean>(false);
  const [showJoinBetsModal, setShowJoinBetsModal] = useState<boolean>(false);
  const [showManageBetsModal, setShowManageBetsModal] = useState<boolean>(false);
  const [hasActiveGames, setHasActiveGames] = useState<boolean>(false);

  const gamesList = useSelector(({ betGames }: ReduxSelectors) => betGames.games);
  
 useEffect(() => {      
    setHasActiveGames(false);
    gamesList.map(({status}) => {
        if (status === "active") {
            setHasActiveGames(true);
        }
      });
 }, [gamesList]);

  return (
    <IonToolbar className="living-room-bets-bar" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <div className="betsButtons">
            <IonButtons placeholder={undefined} onPointerEnterCapture={undefined}> 
                {
                    gamesList.length > 0 &&  hasActiveGames &&    
                    <>
                        <div>
                            <IonButton
                                onClick={() => setShowJoinBetsModal(true)}
                                className="bets-add-game" 
                                title={"Star"} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                        
                                >
                                <IonImg src={joinGame} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />                       
                            </IonButton>
                            <div >{t('Join GAME')}</div>
                        </div>
                    </> 
                }
                {
                    gamesList.length &&    
                    <>            
                        <div>
                            <IonButton
                                onClick={() => setShowManageBetsModal(true)}
                                className="bets-add-game" 
                                title={"Star"} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                       
                                >
                                <IonImg src={manageGame} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />                       
                            </IonButton>
                            <div >{t('Manage GAME')}</div>
                        </div>    
                    </>                             
                }
                {
                    <div>
                        <IonButton
                            onClick={() => setShowAddBetsModal(true)}
                            className="bets-add-game" 
                            title={"Star"} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                        
                            >
                            <IonImg src={addGame} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />                        
                        </IonButton>
                        <div >{t('New GAME')}</div>
                    </div>                                   
                }

            </IonButtons>
         </div>
         <AddBetModal           
            show={showAddBetsModal}
            setShow={setShowAddBetsModal}
            onSelect={(game, type?: messageType) => {
                let message = {
                    type,
                    game
                }
                console.log(JSON.stringify(message));
                session.sendMessage.toEveryone(JSON.stringify(message));                                   
            }}
        />

        <ManageBetModal           
            show={showManageBetsModal}
            setShow={setShowManageBetsModal}
            onSelect={(game: BetGameObj, type?: messageType) => {
                let message = {
                    type,
                    game
                }
                console.log(JSON.stringify(message));
                session.sendMessage.toEveryone(JSON.stringify(message));                
            }}
        />

        <JoinBetModal
            show={showJoinBetsModal}
            setShow={setShowJoinBetsModal}
            onSelect={(game: BetGameObj, type?: messageType) => {
                let message = {
                    type,
                    game
                }
                console.log(JSON.stringify(message));
                session.sendMessage.toEveryone(JSON.stringify(message));   
            }}
        />

        </IonToolbar>
  );
});

export default BetsBar;
